<template>
  <section class="page-container">
    <div ref="toolbar" class="toolbar">
      <el-form :inline="true" :model="filters">
        <el-form-item>
          <el-select v-model="filters.clientType" clearable placeholder="请选择客户端类型" @change="list">
            <el-option label="浏览器" value="0" />
            <el-option label="Android" value="1" />
            <el-option label="微信小程序" value="2" />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select v-model="filters.type" clearable placeholder="请选择信息类别" @change="list">
            <el-option label="产品建议" value="0" />
            <el-option label="功能故障" value="1" />
            <el-option label="其他问题" value="2" />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select v-model="filters.status" clearable placeholder="请选择状态" @change="list">
            <el-option label="正常" value="0" />
            <el-option label="删除" value="1" />
            <el-option label="待审核" value="2" />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="list">查询</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div ref="mainContent" :style="{ height: mainHeight }">
      <el-table
        v-loading="listLoading"
        height="100%"
        :data="results"
        highlight-current-row
        stripe
        border
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="id" label="编号"></el-table-column>

        <!-- <el-table-column prop="uniqueId" label="客户端唯一标识"></el-table-column> -->
        <el-table-column prop="clientType" label="客户端类型">
          <template slot-scope="scope">
            {{
              scope.row.clientType === 0 ? "浏览器" : scope.row.clientType === 1 ? "Android" : "微信小程序"
            }}
          </template>
        </el-table-column>

        <el-table-column prop="type" label="信息类别">
          <template slot-scope="scope">
            {{
              scope.row.type === 0 ? "产品建议" : scope.row.type === 1 ? "功能故障" : "其他问题"
            }}
          </template>
        </el-table-column>

        <el-table-column prop="email" label="邮箱"></el-table-column>

        <el-table-column prop="content" label="内容"></el-table-column>

        <el-table-column prop="status" label="状态">
          <template slot-scope="scope">
            {{ scope.row.status === 0 ? "正常" : scope.row.status === 1 ? "删除" : "待审核" }}
          </template>
        </el-table-column>

        <el-table-column prop="createTime" label="创建时间">
          <template slot-scope="scope">
            {{ scope.row.createTime | formatDate }}
          </template>
        </el-table-column>

        <el-table-column label="操作" width="150">
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.status === 0"
              size="small"
              @click="handleDelete(scope.$index, scope.row)"
              >删除</el-button
            >
            <el-button
              v-if="scope.row.status === 1"
              size="small"
              @click="handleUnDelete(scope.$index, scope.row)"
              >恢复</el-button
            >
            <el-button
              v-if="scope.row.status === 2"
              size="small"
              @click="handlePublish(scope.$index, scope.row)"
              >审核</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div ref="pagebar" class="pagebar">
      <el-pagination
        @current-change="handlePageChange"
        @size-change="handleLimitChange"
        :page-sizes="[20, 50, 100, 300]"
        :current-page="page.page"
        :page-size="page.limit"
        :total="page.total"
        layout="total, sizes, prev, pager, next, jumper"
      >
      </el-pagination>
    </div>
    <el-dialog
      :title="formType === 0 ? '编辑' : '添加'"
      :visible.sync="formVisible"
      :close-on-click-modal="false"
    >
      <el-form :model="form" label-width="140px" ref="form">
        <el-input v-model="form.feedBackId" type="hidden"></el-input>
        <el-form-item label="客户端唯一标识">
          <el-input v-if="formType === 1" v-model="form.uniqueId"></el-input>
          <el-input v-else v-model="form.uniqueId" disabled></el-input>
        </el-form-item>

        <el-form-item label="客户端类型">
          <el-input v-model="form.clientType"></el-input>
        </el-form-item>

        <el-form-item label="信息类别">
          <el-input v-model="form.feedBackType"></el-input>
        </el-form-item>

        <el-form-item label="邮箱">
          <el-input v-model="form.email"></el-input>
        </el-form-item>

        <el-form-item label="内容">
          <el-input type="textarea" v-model="form.content"></el-input>
        </el-form-item>

        <el-form-item label="状态">
          <el-input v-model="form.status"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click.native="formVisible = false">取消</el-button>
        <el-button
          v-if="formType === 0"
          type="primary"
          @click.native="editSubmit"
          :loading="loading"
          >提交</el-button
        >
        <el-button v-else type="primary" @click.native="addSubmit" :loading="loading"
          >提交</el-button
        >
      </div>
    </el-dialog>
  </section>
</template>

<script>
import mainHeight from "@/utils/mainHeight";
export default {
  data() {
    return {
      mainHeight: "300px",
      results: [],
      listLoading: false,
      page: {},
      filters: {},
      selectedRows: [],

      form: {},
      formType: 0,
      formVisible: false,
      loading: false,
    };
  },
  mounted() {
    mainHeight(this);
    this.list();
  },
  methods: {
    async list() {
      const params = Object.assign(this.filters, {
        page: this.page.page,
        limit: this.page.limit,
      });
      try {
        const data = await this.axios.form("/api/admin/feed-back/list", params);
        this.results = data.results;
        this.page = data.page;
      } catch (e) {
        this.$notify.error({ title: "错误", message: e.message || e });
      } finally {
        this.listLoading = false;
      }
    },
    async handlePageChange(val) {
      this.page.page = val;
      await this.list();
    },
    async handleLimitChange(val) {
      this.page.limit = val;
      await this.list();
    },
    handleAdd() {
      this.form = {
        name: "",
        description: "",
      };
      this.formType = 1;
      this.formVisible = true;
    },
    async addSubmit() {
      try {
        await this.axios.form("/api/admin/feed-back/create", this.form);
        this.$message({ message: "提交成功", type: "success" });
        this.formVisible = false;
        await this.list();
      } catch (e) {
        this.$notify.error({ title: "错误", message: e.message || e });
      }
    },
    async handleEdit(index, row) {
      try {
        const data = await this.axios.get("/api/admin/feed-back/" + row.id);
        this.form = Object.assign({}, data);
        this.formType = 0;
        this.formVisible = true;
      } catch (e) {
        this.$notify.error({ title: "错误", message: e.message || e });
      }
    },
    async editSubmit() {
      try {
        await this.axios.form("/api/admin/feed-back/update", this.form);
        await this.list();
        this.formVisible = false;
      } catch (e) {
        this.$notify.error({ title: "错误", message: e.message || e });
      }
    },
    async handleDelete(index, row) {
      const me = this;
      this.$confirm("是否确认删除该反馈信息?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(function () {
          me.axios
            .form("/api/admin/feed-back/delete", { id: row.id })
            .then(function () {
              me.$message({ message: "删除成功", type: "success" });
              me.list();
            })
            .catch(function (err) {
              me.$notify.error({ title: "错误", message: err.message || err });
            });
        })
        .catch(function () {
          me.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    async handleUnDelete(index, row) {
      const me = this;
      this.$confirm("是否确认恢复该反馈信息?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(function () {
          me.axios
            .form("/api/admin/feed-back/undelete", { id: row.id })
            .then(function () {
              me.$message({ message: "恢复成功", type: "success" });
              me.list();
            })
            .catch(function (err) {
              me.$notify.error({ title: "错误", message: err.message || err });
            });
        })
        .catch(function () {
          me.$message({
            type: "info",
            message: "已取消恢复",
          });
        });
    },
    async handlePublish(index, row) {
      const me = this;
      this.$confirm("是否确审核发布该反馈信息?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(function () {
          me.axios
            .form("/api/admin/feed-back/pending", { id: row.id })
            .then(function () {
              me.$message({ message: "审核发布成功", type: "success" });
              me.list();
            })
            .catch(function (err) {
              me.$notify.error({ title: "错误", message: err.message || err });
            });
        })
        .catch(function () {
          me.$message({
            type: "info",
            message: "已取消审核发布",
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
